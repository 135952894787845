import React from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import {
    ErrorCommon,
    TextField,
    TextFieldCommon,
} from "../../../components/formikFormInputs";
import { Label } from "../../../components/formInputs";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import * as Yup from "yup";
import PaymentDocument from "./PaymentDocument";
import { useActions } from "../../../actions";
import * as ClientActions from "../../../actions/client";

const RequestDocumentsSchema = Yup.object().shape({
    documents: Yup.string().required("Required"),
});

export default function RequestDocuments(props: any) {
    const { handleClose } = props;
    const { requestSupportDocuments } = useActions(ClientActions);
    const clientDetail: any = useSelector((state: RootState) => state.clientAccount);
    const clientId: number = clientDetail && clientDetail.client && clientDetail.client.id;
    const formVal = {
        documents: "",
        clientId: clientId
    };
    const [initialValues, setInitialValues] = React.useState(formVal);

    const submitFormik = async (values: any, actions: any) => {
        const supportDocuments = await requestSupportDocuments(values);
        actions.setSubmitting(false);
        actions.resetForm();
        handleClose();
    };

    const fieldObj = [
        {
            id: "documents",
            name: "documents",
            label: <Label label={"Documents"} required={true} />,
            isRequired: true,
            placeholder: "Add Request Supporting Documents ...",
            component: TextField,
            className: "fullWidthcustomFields",
            multiline: true,
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 6,
            },
        },
    ];

    return (
            <div>
                <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={RequestDocumentsSchema}
                        validateOnChange={true}
                        onSubmit={submitFormik}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                      },
                    ) => (<form onSubmit={handleSubmit}>
                        <Grid container spacing={3} className="mt30 mb30">
                            {fieldObj.map((field, index) => {
                                return (
                                        <Grid item xs={field.size.xs === 6 ? 6 : 12}
                                              md={field.size.md === 6 ? 6 : 12}
                                              key={index} className="mb5">
                                            <TextFieldCommon
                                                    {...field}
                                                    values={values}
                                                    onChange={handleChange}
                                            />
                                            <ErrorCommon errors={errors} name={field.name}
                                                         touched={touched} />
                                        </Grid>
                                );
                            })
                            }
                            <Grid item xs={12} md={12}>
                                <PaymentDocument fieldName={"paymentDocumentPath"} />
                            </Grid>
                        </Grid>
                        <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="ml20 floatRight"
                                size="small"
                                disabled={isSubmitting}
                        >
                            Save
                        </Button>
                    </form>)}
                </Formik>
            </div>
    );
}
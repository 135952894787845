import React, { useState } from "react";
import {
    Checkbox,
    DialogContent,
    Grid,
    Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { AddEditPhysicianDialog as dialogStyles } from "../beneficiaryStyle";
import GetFormContent from "./GetFormContent";
import { Label } from "../../../../../../../components/formInputs";
import {
    NumberTextField,
    PhoneNumberField,
    SelectField,
    SSNNumberField,
    TextField,
} from "../../../../../../../components/formikFormInputs";
import * as Yup from "yup";
import {
    ALPHABET_ONLY,
    ALPHABET_WITH_HYPHEN_DOT_REGEX,
    relationshipDropDown,
    REQUIRED_FIELD,
    stateDropDown, ZIP_CODE_ALPHA_NUMERIC_CHAR,
} from "../../../../../../../constants";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as AccountActions from "../../../../../../../actions/account";
import { useActions } from "../../../../../../../actions";
import * as Aws from "../../../../../../../actions/aws";
import StrategyName from "../../../../../../../components/client/StrategyName";
import AddEditPhysicianForm from './AddEditPhysicianForm';
import { maskPhoneNumber, noop, pez } from "../../../../../../../utils/commonUtils";
import DatePicker from "../../../../../../../components/formikFormInputs/DatePicker";
import { YupTestCases } from "../../../../../../../components/dynamicForms";
import { noAncientDate, noFutureDate } from "../../../../../../../utils/validation";
import { isAdmin } from "../../../../../../../components/navigation/AdminNavigation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../reducers";
type placeObj = { id: number, key?: string, name: string, value: string };
let stateDropDownArr: placeObj[] = stateDropDown.map((state: any) => {
    return {
        id: state.id,
        key: state.value,
        name: state.name,
        value: state.value,
    };
});
const DialogTitle = (props: {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}) => {
    const { children, onClose, ...other } = props;
    const classes = dialogStyles();

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const AddEditPhysician = (props: any) => {
    const awsActions = useActions(Aws);
    const {
        percent,
        record,
        open,
        setOpen,
        beneficiaryType,
        user,
        isFromClientDetail,
        isCommunityState,
        marriageStatusValue,
        isExistSpouseEntry,
    } = props;

    const [isTrust, setIsTrust] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [isSelected, setIsSelected] = React.useState(false);
    const [checked, setChecked] = React.useState((record && record.isClientAddress) || false);
    const { saveBeneficiary } = useActions(AccountActions);
    const authUser = useSelector((state: RootState) => state.auth.user);
    const isClientSigned = !isAdmin(authUser) && (user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId)) || false;
    const strategyName = (user.client && user.client.strategy && user.client.strategy.name) || "Kai-Zen";
    const maritalStatus: string = (marriageStatusValue === "Married" && !isExistSpouseEntry) ? "SPOUSE" : "";
    const [externalValue, setExternalValue] = useState((record && record.relationship) || maritalStatus || "");
    const [trustDate, setTrustDate] = React.useState(false);
    let form1InitialValues = {
        relationship: (record && record.relationship) || maritalStatus || "",
        deathBenefitPercentage: (record && record.deathBenefitPercentage) || "",
        otherRelationship: (record && record.otherRelationship) || "",
    };
    /*To Remain Form 1 values after submit step 1*/
    const [step1Values, setStep1Values] = React.useState<any>(form1InitialValues);
    /*To Set Form Initial Values it will manage Both step Form*/
    const [initialValues, setInitialValues] = React.useState<any>(form1InitialValues);

    const relationshipDropDownOptions = relationshipDropDown.filter((rel: any) => {
        if (record && record.relationship == "SPOUSE" && rel.value === "SPOUSE") {
            return true;
        }
        if (rel.value === "SPOUSE" && marriageStatusValue !== "Married" && isCommunityState) { //Set for Both Type Beneficiary
            return false;
        }
        if (rel.value === "SPOUSE" && isExistSpouseEntry && !record) {
            return false;
        }
        if (rel.value === "SPOUSE" && isExistSpouseEntry && record && (record.relationship !== "SPOUSE")) {
            return false;
        }
        return true;
    });

    const fieldObj = [
        {
            id: "relationship",
            name: "relationship",
            helperFieldText: "",
            label: <Label label={"Relationship"} required={true} />,
            placeholder: "Relationship",
            isRequired: true,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: SelectField,
            options: relationshipDropDownOptions,
            onSelectChange: (kval: any) => {
                if (kval !== "TRUST") {
                    setIsTrust(false)
                }
                setExternalValue(kval)
            },
            variant: "outlined",
            size: {
                md: 4,
                lg: 4,
            },
            show: true,
        },
        {
            id: "otherRelationship",
            name: "otherRelationship",
            helperFieldText: "",
            label: <Label label={"Describe Relationship"} required={true} />,
            placeholder: "Describe Relationship",
            isRequired: true,
            disabled: isClientSigned,
            isHide: true,
            className: "mt10",
            filedType: "string",
            component: TextField,
            variant: "outlined",
            size: {
                md: 4,
                lg: 4,
            },
            show: true,
        },
        {
            id: "deathBenefitPercentage",
            name: "deathBenefitPercentage",
            helperFieldText: "",
            label: <Label label={"Death Benefit Percentage"} required={true} />,
            placeholder: "Death Benefit Percentage",
            isRequired: true,
            disabled: isClientSigned,
            className: "mt10",
            filedType: "number",
            min: 1,
            max: 100,
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment disableTypography={true}
                    position="start">%</InputAdornment>,
            },
            variant: "outlined",
            size: {
                md: 4,
                lg: 4,
            },
            show: true,
        },
    ];
    let form2InitialValues = {
        firstName: (record && record.firstName) || "",
        mi: (record && record.mi) || "",
        lastName: (record && record.lastName) || "",
        address: (record && record.address) || "",
        city: (record && record.city) || "",
        state: (record && record.state) || "",
        zip: (record && record.zip) || "",
        ssn: (record && record.ssn) || "",
        email: (record && record.email) || "",
        phone: (record && record.phone) ? maskPhoneNumber(String(record.phone)) : "",
    };

    if (isTrust && !!record) {
        Object.assign(form2InitialValues, { trustLegalName: record.trustLegalName, trustDate: record.trustDate });
    }
    if (!isTrust && externalValue === "ESTATE") {
        Object.assign(form2InitialValues, {relationship: pez(step1Values.relationship), dob: record && pez(record.dob)});
    }

    const fieldObjForm2 = isTrust ? [
        {
            component: TextField,
            disabled: isClientSigned,
            id: "trustLegalName",
            isRequired: isTrust,
            label: <Label label={"Full Legal Trust Name"} />,
            name: "trustLegalName",
            placeholder: "Full Legal Trust Name",
            size: { lg: 8, md: 8 },
            variant: "outlined",
            filedType: "Alphabet",
            show: true,
        },
        {
            component: DatePicker,
            disabled: isClientSigned,
            id: "trustDate",
            isRequired: isTrust,
            label: <Label label={"Trust Date"} />,
            name: "trustDate",
            placeholder: "Trust Date",
            size: { lg: 4, md: 4 },
            variant: "outlined",
            cb: (date: string) => {
                if (date === 'Invalid date') {
                    setTrustDate(true);
                } else {
                    setTrustDate(false);
                }
            },
            show: true,
        },
        {
            component: TextField,
            disabled: isClientSigned || checked,
            filedType: "string",
            helperFieldText: "",
            id: "address",
            isRequired: isTrust,
            label: <Label label={"Address"} required={!isFromClientDetail} />,
            name: "address",
            placeholder: "Address",
            size: { lg: 3, md: 3 },
            variant: "outlined",
            show: true,
        },
        {
            className: "mt10",
            component: TextField,
            disabled: isClientSigned || checked,
            filedType: "Alphabet",
            helperFieldText: "",
            id: "city",
            isRequired: isTrust,
            label: <Label label={"City"} required={!isFromClientDetail} />,
            name: "city",
            placeholder: "City",
            size: { lg: 3, md: 3 },
            variant: "outlined",
            show: true,
        },
        {
            className: "mt10",
            component: SelectField,
            disabled: isClientSigned || checked,
            filedType: "Alphabet",
            helperFieldText: "",
            id: "state",
            isRequired: isTrust,
            label: <Label label={"State"} required={!isFromClientDetail} />,
            name: "state",
            onSelectChange: noop,
            options: stateDropDownArr,
            placeholder: "State",
            size: { lg: 3, md: 3 },
            variant: "outlined",
            show: true,
        },
        {
            className: "mt10",
            component: TextField,
            disabled: isClientSigned || checked,
            filedType: "zip",
            helperFieldText: "",
            id: "zip",
            isRequired: isTrust,
            label: <Label label={"Zip"} required={!isFromClientDetail} />,
            name: "zip",
            placeholder: "Zip",
            size: { md: 3, lg: 3 },
            variant: "outlined",
            show: true,
        },
        {
            className: "mt10",
            component: TextField,
            disabled: isClientSigned,
            filedType: "Alphabet",
            helperFieldText: "",
            id: "firstName",
            isRequired: isTrust,
            label: <Label label={"First Name"} required={true} />,
            name: "firstName",
            placeholder: "First Name",
            size: { lg: 3, md: 3 },
            variant: "outlined",
            show: true,
        },
        {
            className: "mt10",
            component: TextField,
            disabled: isClientSigned,
            filedType: "Alphabet",
            helperFieldText: "",
            id: "lastName",
            isRequired: isTrust,
            label: <Label label={"Last Name"} required={true} />,
            name: "lastName",
            placeholder: "Last Name",
            size: { lg: 3, md: 3 },
            variant: "outlined",
            show: true,
        },
        {
            component: SSNNumberField,
            disabled: isClientSigned,
            id: "ssn",
            isRequired: isTrust,
            label: <Label label={"Trust EIN Number"} />,
            name: "ssn",
            placeholder: "Trust EIN Number",
            size: { lg: 3, md: 3 },
            variant: "outlined",
            show: true,
        },
        {
            component: PhoneNumberField,
            disabled: isClientSigned,
            id: "phone",
            isRequired: isTrust,
            label: <Label label={"Contact Phone"} />,
            name: "phone",
            placeholder: "Phone",
            size: { lg: 3, md: 3 },
            variant: "outlined",
            show: true,
        },
        {
            id: "relationship",
            name: "relationship",
            helperFieldText: "",
            label: <Label label={"Relationship"} required={externalValue === "ESTATE"} />,
            placeholder: "Relationship",
            isRequired: externalValue === "ESTATE",
            disabled: externalValue === "ESTATE",
            className: "mt10",
            filedType: "string",
            component: SelectField,
            options: relationshipDropDownOptions,
            onSelectChange: (kval: any) => {
                setExternalValue(kval)
            },
            variant: "outlined",
            show: externalValue === "ESTATE",
            size: {
                md: 3,
                lg: 3,
                xl: 3,
            },
            isHide: externalValue !== "ESTATE",
        },
        {
            id: "dob",
            name: "dob",
            helperFieldText: "",
            label: <Label label={"Date of Birth"} required={externalValue === "ESTATE"} />,
            placeholder: "Date of Birth",
            isRequired: externalValue === "ESTATE",
            disabled: isClientSigned,
            className: "mt10",
            filedType: "string",
            component: DatePicker,
            variant: "outlined",
            show: externalValue === "ESTATE",
            disableFuture: true,
            size: {
                md: 3,
                lg: 3,
                xl: 3,
            },
            isHide: externalValue !== "ESTATE",
        },
    ] :
        [
            {
                id: "firstName",
                name: "firstName",
                helperFieldText: "",
                label: <Label label={externalValue === "ESTATE" ? "Estate Name" : "First Name"} required={true} />,
                placeholder: "First Name",
                isRequired: true,
                disabled: isClientSigned,
                className: "mt10",
                filedType: "Alphabet",
                component: TextField,
                variant: "outlined",
                show: true,
                size: {
                    xs: externalValue === "ESTATE" ? 12 : 6,
                    md: externalValue === "ESTATE" ? 12 : 6,
                    lg: externalValue === "ESTATE" ? 12 : 6,
                    xl: externalValue === "ESTATE" ? 12 : 6,
                }
            },
            {
                id: "mi",
                name: "mi",
                helperFieldText: "",
                label: <Label label={"MI"} required={false} />,
                placeholder: "MI",
                isRequired: false,
                disabled: isClientSigned,
                customClass: "displayNone",
                className: "mt10",
                filedType: "Alphabet",
                component: TextField,
                variant: "outlined",
                show: externalValue !== "ESTATE",
                size: {
                    xs: 12,
                    md: 6,
                },
                // isHide: externalValue === "ESTATE",
            },
            {
                id: "lastName",
                name: "lastName",
                helperFieldText: "",
                label: <Label label={"Last Name"} required={true} />,
                placeholder: "Last Name",
                isRequired: externalValue !== "ESTATE",
                disabled: isClientSigned,
                customClass: "displayNone",
                className: "mt10",
                filedType: "Alphabet",
                component: TextField,
                variant: "outlined",
                // isHide: externalValue === "ESTATE",
                show: externalValue !== "ESTATE",
                size: {
                    md: 6,
                    lg: 6,
                }
            },
            {
                id: "address",
                name: "address",
                helperFieldText: "",
                label: <Label label={"Address"} required={!isFromClientDetail} />,
                placeholder: "Address",
                disabled: isClientSigned || checked,
                isRequired: !isFromClientDetail,
                className: "mt10",
                filedType: "string",
                component: TextField,
                variant: "outlined",
                show: true,
                size: {
                    xs: 12,
                    md: 6,
                    lg: 6,
                    xl: 6,
                }
            },
            {
                id: "email",
                name: "email",
                helperFieldText: "",
                label: <Label label={"Email"} required={true} />,
                placeholder: "Email",
                isRequired: externalValue !== "ESTATE",
                disabled: isClientSigned,
                className: "mt10",
                filedType: "email",
                component: TextField,
                variant: "outlined",
                size: {
                    md: 6,
                    lg: 6,
                },

                show: externalValue !== "ESTATE",
            },
            {
                id: "city",
                name: "city",
                helperFieldText: "",
                label: <Label label={"City"} required={!isFromClientDetail} />,
                placeholder: "City",
                isRequired: !isFromClientDetail,
                disabled: isClientSigned || checked,
                className: "mt10",
                filedType: "Alphabet",
                component: TextField,
                variant: "outlined",
                show: true,
                size: {
                    xs: 12,
                    md: 2,
                    lg: 2,
                    xl: 2,
                }
            },
            {
                id: "zip",
                name: "zip",
                helperFieldText: "",
                label: <Label label={"Zip"} required={!isFromClientDetail} />,
                placeholder: "Zip",
                isRequired: !isFromClientDetail,
                disabled: isClientSigned || checked,
                className: "mt10",
                filedType: "string",
                component: TextField,
                variant: "outlined",
                show: true,
                size: {
                    xs: 12,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                }
            },
            {
                id: "relationship",
                name: "relationship",
                helperFieldText: "",
                label: <Label label={"Relationship"} required={externalValue === "ESTATE"} />,
                placeholder: "Relationship",
                isRequired: externalValue === "ESTATE",
                disabled: externalValue === "ESTATE",
                className: "mt10",
                filedType: "string",
                component: SelectField,
                options: relationshipDropDownOptions,
                onSelectChange: (kval: any) => {
                    setExternalValue(kval)
                },
                variant: "outlined",
                show: externalValue === "ESTATE",
                size: {
                    md: 3,
                    lg: 3,
                    xl: 3,
                },
                isHide: externalValue !== "ESTATE",
            },
            {
                id: "dob",
                name: "dob",
                helperFieldText: "",
                label: <Label label={"Date of Birth"} required={externalValue === "ESTATE"} />,
                placeholder: "Date of Birth",
                isRequired: externalValue === "ESTATE",
                disabled: isClientSigned,
                className: "mt10",
                filedType: "string",
                component: DatePicker,
                variant: "outlined",
                show: externalValue === "ESTATE",
                disableFuture: true,
                size: {
                    md: 3,
                    lg: 3,
                    xl: 3,
                },
                isHide: externalValue !== "ESTATE",
            },
            {
                id: "state",
                name: "state",
                helperFieldText: "",
                label: <Label label={"State"} required={!isFromClientDetail} />,
                placeholder: "State",
                isRequired: !isFromClientDetail,
                disabled: isClientSigned || checked,
                className: "mt10",
                filedType: "Alphabet",
                component: SelectField,
                options: stateDropDownArr,
                onSelectChange: (val: number) => {
                },
                variant: "outlined",
                show: true,
                size: {
                    xs: 12,
                    md: 2,
                    lg: 2,
                    xl: 2,
                }
            },
            {
                id: "ssn",
                name: "ssn",
                helperFieldText: "",
                label: <Label label={"Social Security"} required={true} />,
                placeholder: "Social Security",
                isRequired: true,
                disabled: isClientSigned,
                className: "mt10",
                filedType: "string",
                component: SSNNumberField,
                variant: "outlined",
                size: {
                    md: 3,
                    lg: 3,
                    xl: 3,
                },
                show: true,
            },
            {
                id: "phone",
                name: "phone",
                show: true,
                helperFieldText: "",
                label: <Label label={"Contact Phone"} required={true} />,
                placeholder: "Phone",
                isRequired: true,
                disabled: isClientSigned,
                className: "mt10",
                filedType: "numeric",
                component: PhoneNumberField,
                variant: "outlined",
                size: {
                    md: 3,
                    lg: 3,
                    xl: 3,
                }
            },
        ];


    /* Validations of fields set */
    let keysValidation = {};
    let fields: any = fieldObj;
    if (activeStep === 1) {
        fields = fieldObjForm2;
    }
    fields.forEach((field) => {
        if (field.isRequired) {
            keysValidation[field.name] = field.filedType && field.filedType === "number" ?
                Yup.number().min(1).required(REQUIRED_FIELD) : Yup.string().trim().required(REQUIRED_FIELD).nullable();
            if (field.name === "firstName" || field.name === "lastName" || field.name === "trustLegalName" || field.name === "city") {
                keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable();
            }
            if(field.name === "trustDate" || field.name === "dob"){
                keysValidation[field.name] = Yup.string()
                        .test(YupTestCases.DateFuture.name, YupTestCases.DateFuture.message, noFutureDate)
                        .test(YupTestCases.DateAncient.name, YupTestCases.DateAncient.message, noAncientDate)
                        .required(REQUIRED_FIELD)
                        .nullable()
            }
            if (field.max) {
                keysValidation[field.name] = Yup.number().min(1).max(100).required(REQUIRED_FIELD).test("len", "Please enter min 1 value", (val = []) => ((val.length || 0) < 1));
            }
            if (field.name === "otherRelationship") {
                keysValidation[field.name] = Yup.string().trim().when("relationship",
                    {
                        is: "OTHER",
                        then: Yup.string().trim().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD).nullable(),
                    });
            }

            if (field.filedType === "email") {
                keysValidation[field.name] = Yup.string().trim().email().required(REQUIRED_FIELD);
            }

            if (field.name === "zip") {
                keysValidation[field.name] = Yup.string().matches(/^[0-9]+$/, "Must be only digits").test("len", "Please enter 5 characters", (val = []) => {
                    if ((val.length || 0) === 0) {
                        return true;
                    }
                    return (val.length || 0) === 5;
                }).required(REQUIRED_FIELD);
            }
            if (field.max) {
                keysValidation[field.name] = Yup.number().typeError("Please enter between 1-100")
                    .min(1, "Please enter between 1-100")
                    .max(100, "Please enter between 1-100")
                    .integer("Decimal value is not allowed")
                    .required(REQUIRED_FIELD);
            }
            if (
                field.name === "deathBenefitPercentage" && isCommunityState && !isSelected && beneficiaryType === "Primary"
            ) {
                keysValidation[field.name] = Yup.number().when("relationship", {
                    is: "SPOUSE",
                    then: Yup.number().integer("Decimal value is not allowed").typeError("Must be a number").min(50, "50% or more required for spouse").max(100, "50% or more required for spouse").required(REQUIRED_FIELD),
                    otherwise: Yup.number().integer("Decimal value is not allowed").typeError("Must be a number").min(1, "Please enter between 1-100").max(100, "Please enter between 1-100").required(REQUIRED_FIELD),
                });
            }
            if (field.name === "ssn") {
                keysValidation[field.name] = Yup.string().transform((o, v) => {
                    let value = v + "";
                    if (value) {
                        value = value.replace(/\D+/g, "");
                    }
                    return value;
                }).length(9, "Enter Valid Number").required(REQUIRED_FIELD);
            }
            if (field.name === "phone") {
                keysValidation[field.name] = Yup.string().matches(/^\([1-9]\d{2}\)\s?\d{3}-\d{4}$/, "Invalid Phone Number").required(REQUIRED_FIELD);
            }
        } else {
            keysValidation[field.name] = field.filedType &&
                field.filedType === "number" ? Yup.number().nullable() : Yup.string().nullable();
            if (field.name === "mi") {
                keysValidation[field.name] = Yup.string().matches(ALPHABET_WITH_HYPHEN_DOT_REGEX, ALPHABET_ONLY).nullable();
            }
            if (field.filedType === "email") {
                keysValidation[field.name] = Yup.string().email();
            }
            if (field.name === "zip") {
                keysValidation[field.name] = Yup.string().matches(ZIP_CODE_ALPHA_NUMERIC_CHAR, "Special characters are not allowed").test("len", "Please enter 5 characters", (val = []) => {
                    if ((val.length || 0) === 0) {
                        return true;
                    }
                    return (val.length || 0) === 5;
                });
            }
            if (field.max) {
                keysValidation[field.name] = Yup.number().typeError("Must be a number").integer("Decimal value is not allowed").min(1, "Please enter between 1-100").max(100, "Please enter between 1-100");
            }
            if (
                field.name === "deathBenefitPercentage" && isCommunityState && !isSelected && beneficiaryType === "Primary"
            ) {
                keysValidation[field.name] = Yup.number().when("relationship", {
                    is: "SPOUSE",
                    then: Yup.number().integer("Decimal value is not allowed").typeError("Must be a number").min(50, "50% or more required for spouse").max(100, "50% or more required for spouse"),
                    otherwise: Yup.number().integer("Decimal value is not allowed").typeError("Must be a number").min(1, "Please enter between 1-100").max(100, "Please enter between 1-100"),
                });
            }
            if (field.name === "ssn") {
                keysValidation[field.name] = Yup.string().transform((o, v) => {
                    let value = v + "";
                    if (value) {
                        value = value.replace(/\D+/g, "");
                    }
                    return value;
                }).length(9, "Enter Valid Number");
            }
            if (field.name === "phone") {
                keysValidation[field.name] = Yup.string().transform((o, v) => {
                    let value = v + "";
                    if (value) {
                        value = value.replace(/\D+/g, "");
                    }
                    return value;
                }).length(10, "Enter Valid Number");
            }
        }
    });

    const validationSchema = Yup.object().shape(keysValidation);

    const handleNext = () => {
        if (activeStep === 1) {
            return false;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setInitialValues(step1Values);
        setIsSelected(false);

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function getSteps() {
        return [
            "Relationship and Percentage",
            <p>
                <span>Beneficiary Details</span>

                <span className="ml15" id="attestOfTruth">
                    <Checkbox style={{ height: "28px", marginRight: "10px" }}
                              className="pd0 mr10" color={"primary"}
                              value={checked}
                              checked={checked}
                              disabled={isClientSigned || activeStep === 0}
                              onChange={(e, v) => {
                                  setChecked(e.target.checked);

                            let addressData: any = {
                                address: "",
                                city: "",
                                state: "",
                                zip: "",
                            };
                            /*Get Client Data*/
                            let clientData: any = (user && user.client && user.client) || undefined;

                            if (e.target.checked && clientData) {
                                addressData = {
                                    address: clientData.street || "",
                                    city: clientData.city || "",
                                    state: clientData.state || "",
                                    zip: clientData.zipCode || "",
                                };
                            }
                            if (!isTrust && externalValue === "ESTATE") {
                                addressData.relationship = pez(step1Values.relationship);
                                      addressData.dob =  record && pez(record.dob);
                            }
                            setInitialValues({ ...form2InitialValues, ...addressData });
                        }}
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
                    <Typography variant="caption" style={{ marginTop: 2 }}>
                        <span><strong> Use Client's Address</strong> </span>
                    </Typography>
                </span>
            </p>
        ];
    }

    const steps = getSteps();

    function getStepContent(step: number, formikProps: any) {
        let fields = fieldObj;
        if (formikProps.values && formikProps.values.relationship === "OTHER") {
            fields = fieldObj.map(filed => {
                if (filed && filed.name === "otherRelationship") {
                    filed.isHide = false;
                }
                return filed;
            });
        } else if (formikProps.values && formikProps.values.relationship === "TRUST") {
            setIsTrust(true);
        } else {
            fields = fieldObj.map(filed => {
                if (filed && filed.name === "otherRelationship") {
                    filed.isHide = true;
                }
                return filed;
            });
        }
        switch (step) {
            case 0:
                return <GetFormContent isFromClientDetail={isFromClientDetail} fieldObj={fields} {...formikProps} />;
            case 1:
                return <GetFormContent isFromClientDetail={isFromClientDetail}
                    fieldObj={fieldObjForm2} {...formikProps} />;
            default:
                return "Unknown step";
        }
    }

    const downloadForm = async (documentUri: string) => {
        let dirName: string = "enrollment";
        let file = documentUri.split(".");
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF" || fileType == "pdf") ? ("application/pdf") : ("application/vnd.openxmlformats-officedocument.presentationml.presentation");
        let queryObj = {
            objectName: documentUri,
            contentType: contentType,
        };
        const s3url = await awsActions.getSecureUrlAndDownload(dirName, queryObj);
        if (s3url && s3url.Body) {
            let blob = new Blob([new Buffer(s3url.Body, "binary")]
                , { type: s3url.ContentType });
            const url = window.URL.createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download", documentUri);
            window.document.body.appendChild(link);
            link.click();
        }
    };

    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth={"md"} className="djfndf dsjofof"
            aria-labelledby="customized-dialog-title"
            open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {!record ? "Add " : "Edit "} {beneficiaryType} Beneficiary
            </DialogTitle>
            <DialogContent dividers style={{ padding: "15px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1">
                            Primary beneficiary(ies) receives the
                            <StrategyName strategyName={strategyName} withText={true} ucFirst={true} />
                            benefit (minus loan repayment) at the time of
                            the insured’s death. If there are no living primary beneficiaries at the time of the
                            insured’s
                            death, the benefits will be paid to contingent beneficiary(ies). If insured’s policy has
                            more
                            than one primary beneficiary, benefits will be divided equally among the living
                            beneficiaries,
                            unless specific percentage is otherwise specified. <strong>The percentages for all the
                                primary
                                beneficiaries must total 100%, and all contingent beneficiaries must total
                                100%.</strong> If a
                                beneficiary dies before the insured, the shares will be reallocated to the living
                                beneficiaries
                                (prorated by percentage). If none of the listed beneficiaries are living at the time of
                                the
                                insured’s death, the benefits will be paid to the insured’s Estate. We cannot accept any
                                UTMAs.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Stepper activeStep={activeStep} orientation="vertical" style={{ padding: "10px" }}>
                            {steps.map((label, index) => (
                                <Step key={index}>
                                    <StepLabel className="f18">{label}</StepLabel>
                                    <StepContent>
                                        <AddEditPhysicianForm
                                            setExternalValue={setExternalValue}
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            activeStep={activeStep}
                                            step1Values={step1Values}
                                            beneficiaryType={beneficiaryType}
                                            user={user} steps={steps}
                                            checked={checked}
                                            percent={percent}
                                            saveBeneficiary={saveBeneficiary}
                                            handleClose={handleClose}
                                            record={record}
                                            setStep1Values={setStep1Values}
                                            setInitialValues={setInitialValues}
                                            form2InitialValues={form2InitialValues}
                                            handleNext={handleNext}
                                            isCommunityState={isCommunityState}
                                            getStepContent={getStepContent} handleBack={handleBack}
                                            index={index} setIsSelected={setIsSelected}
                                            isClientSigned={isClientSigned}
                                            downloadForm={downloadForm}
                                            trustDate={trustDate}
                                        />
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AddEditPhysician;
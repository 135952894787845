import * as React from "react";
import { useEffect, useState } from "react";
import { Card, CardContent, Grid, Link, Typography } from "@material-ui/core";
import { useActions } from "../actions";
import { Formik } from "formik";
import * as AccountActions from "../actions/account";
import {
    MARKETING_SITE_URL,
    PASSWORD_VALIDATE_REGEX,
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_ONE_SPECIAL_CHAR,
} from "../constants";
import PasswordRequired from "./PasswordRequired";
import { ErrorCommon, TextField, TextFieldCommon } from "../components/formikFormInputs";
import { Button, Label, PageTitle } from "../components/formInputs";
import LandingContactForm from "../../src/pages/client/Invitation/v2/LandingContactForm";
import { pez } from "../utils/commonUtils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import logo from "../styles/images/logo-ilia.svg";
import logo2 from "../styles/images/login.png";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
        createStyles({
            regWrap: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                verticalAlign: "middle",
                height: "100%",
                width: "100%",
                "&::after": {
                    content: "\"\"",
                    position: "absolute",
                    background: "#3971EC",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    width: "42%",
                    [theme.breakpoints.down("sm")]: {
                        position: "inherit",
                        background: "transparent",
                        width: "auto",
                    },
                },

            },
            textWhite: {
                color: "#FFFFFF",
            },
            textCenter: {
                textAlign: "center",
            },
            mt10: {
                marginTop: 10,
            },
            mr30: {
                marginRight: 30,
            },
            regCard: {
                margin: "auto",
                width: 865,
                boxShadow: "0px 0px 45px 0px rgb(0 0 0 / 30%) !important",
                background: "transparent !important",
                left: 0,
                right: 0,
                zIndex: 999,
                border: 0,
                padding: "2%",
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                    background: "#ffffff !important",
                    width: "100%",
                    margin: "0 15px",
                },
            },
            muiCardContent: {
                padding: 15,
                float: "left",
                width: "100%",
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 16px 0",
                },
            },
            regForm: {
                padding: "60px 15px 50px",
                float: "left",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                    padding: "30px 0 15px",
                },
            },
            margin0: {
                margin: 0,
            },
            titleMain: {
                fontSize: 26,
                lineHeight: "34px",
                textAlign: "center",
                margin: 0,
                float: "right",
            },
            dpNone: {
                [theme.breakpoints.down(992)]: {
                    display: "none",
                },
            },
            floatRight: {
                float: "right",
            },
            imgResponsive: {
                maxWidth: "100%",
                height: "auto",
                display: "block",
                marginTop: "25%",
            },
            clickableLink: {
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
            },
            clickableLinksvg: {
                float: "left",
            },
            mr10: {
                marginRight: 10,
            },
            mt15: {
                marginTop: 15,
            },
        }),
);

const Register = (props: any) => {
    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordError, setNewPasswordError] = React.useState("");
    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showPasswordRequirementSection, setShowPasswordRequirementSection] = React.useState<boolean>(false);
    const accountActions = useActions(AccountActions);
    const classes = useStyles();
    let searchParams: string = props && props.location.search;
    const UrlClass = new URLSearchParams(searchParams);
    const agentLinkId = UrlClass.get("agentLink");
    const emailFromParam = UrlClass.get("token") ? atob((UrlClass.get("token")) as string) : "";
    const [activeForm, setActiveForm] = useState(Number("0"));
    const [timerFinished, setTimerFinished] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);

    const onPassWordChange = (e: any): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
        setNewPassword(e.target.value);

        val ?
                ((!PWD_ONE_LETTER_REGEX.test(val) || !PWD_ONE_CAPITAL_LETTER_REGEX.test(val) || !PWD_ONE_NUMBER_REGEX.test(val) || !PWD_MIN_EIGHT_CHAR_REGEX.test(val) || !PWD_ONE_SPECIAL_CHAR.test(val)) ? setNewPasswordError("Enter valid password") : setNewPasswordError(""))
                :
                setNewPasswordError("Required");
    };

    let url: string = "/login";

    const fieldObj = [
        {
            label: <Label label="First Name" required={true} />,
            name: "firstName",
            placeholder: "First Name",
            id: "firstName",
            component: TextField,
            type: "text",
        }, {
            label: <Label label="Last Name" required={true} />,
            name: "lastName",
            placeholder: "Last Name",
            id: "lastName",
            component: TextField,
            type: "text",
        }, {
            label: <Label label="Email" required={true} />,
            name: "email",
            placeholder: "email",
            id: "email",
            component: TextField,
            type: "text",
        }, {
            label: <Label label="Password" required={true} />,
            name: "password",
            placeholder: "Password",
            id: "password",
            component: TextField,
            type: "password",

        }, {
            label: <Label label="Confirm Password" required={true} />,
            name: "confirmPassword",
            placeholder: "confirmPassword",
            id: "confirmPassword",
            component: TextField,
            type: "password",
        }];

    const fieldObj1 = [{
        label: <Label label="Otp" required={true} />,
        name: "otp",
        placeholder: "Enter OTP",
        id: "otp",
        component: TextField,
        type: "password",
    }];

    const fields = [
        fieldObj,
        fieldObj1,
    ];

    const intitialValues = {
        agentLinkId: agentLinkId,
        firstName: "",
        lastName: "",
        email: emailFromParam || "",
        password: "",
        confirmPassword: "",
    };

    const validate = ((value: any) => {
        const errors: {
            firstName?: string,
            lastName?: string,
            email?: string,
            password?: string | boolean,
            confirmPassword?: string
            otp?: string,
        } = {};
        switch (activeForm) {
            case 0:
                if (!value.firstName)
                    errors.firstName = "Required";
                if (!value.lastName)
                    errors.lastName = "Required";
                if (!value.email)
                    errors.email = "Required";
                if (!value.password)
                    errors.password = "Required";
                if (!value.confirmPassword) {
                    errors.confirmPassword = "Required";
                } else if (value.confirmPassword !== value.password) {
                    errors.confirmPassword = "Password mismatched";
                }
                if (value.password && !PASSWORD_VALIDATE_REGEX.test(value.password)) {
                    errors.password = true;
                }
                break;
            case 1:
                if (!value.otp) {
                    errors.otp = "Required";
                }
                break;
        }
        return errors;
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000); // Update every second

        // Clear the timer and set timerFinished to true when time is up
        if (timeLeft === 0 || timeLeft < 0) {
            clearInterval(timer);
            setTimerFinished(true);
        }

        // Clear the timer when the component unmounts
        return () => clearInterval(timer);
    }, [timeLeft]);

    const handleResendClick = async (values) => {
        let payload: any = { email: values["email"] || emailFromParam };
        let details: any = await accountActions.resendOtp(payload);
        if (details) {
            setTimeLeft(5 * 60);
            setTimerFinished(false);
        }

    };
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {

        switch (activeForm) {
            case 0:
                let payload: any = { agentLinkId: agentLinkId, ...values };

                let details: any = await accountActions.createAgentClient(payload);
                if (details && details.id) {
                    setActiveForm((activeForm) => activeForm + 1);
                    props.history.push({
                        pathname: `${props.location.pathname}`,
                        search: `?${new URLSearchParams({
                            landing: "1",
                            token: btoa(pez(values["email"] as string)),
                        })}`,
                    });
                    setTimeLeft(5 * 60);
                    setTimerFinished(false);
                }
                break;
            case 1:
                let payload1: any = { otp: values["otp"], email: values["email"] };
                let res: any = await accountActions.verifyGroupClient(payload1);
                if (res && res.type != 0) {
                    resetForm();
                    window.location.href = ("/login");
                }
                break;
        }
    };

    const forms = [
        {
            buttonText: "CONTINUE",
            bottomTitleText: "",
            fields: fieldObj,
            inv: { firstName: "", lastName: "", email: "" },
            titleText: "Create Your Account",
            subTitleText: "",
        },
        {
            buttonText: "Confirm OTP",
            bottomTitleText: "Codes can take up to 5 minutes to arrive. Check your spam folder.",
            fields: fieldObj1,
            inv: { otp: "" },
            titleText: (<>Please confirm the One Time Pass-code (OTP)<br />sent to the email you registered with.</>),
            subTitleText: "Please check your email for one-time verifications code so we can confirm your ID.",

        },
    ];

    return (
            <React.Fragment>
                <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/layout.css`} />
                <div className={classes.regWrap}>
                    <PageTitle title="Create Your Account" />
                    <Card className={classes.regCard}>
                        <CardContent className={classes.muiCardContent}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={7} lg={7} className={classes.textCenter}>
                                    <img width="260" alt="logo" src={logo} />
                                    <Formik
                                            initialValues={intitialValues}
                                            validate={values => validate(values)}
                                            onSubmit={(values, {
                                                setSubmitting,
                                                resetForm
                                            }) => submitFormik(values, setSubmitting, resetForm)}
                                    >
                                        {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                              isSubmitting,
                                              dirty
                                          }) => {
                                            return (<>
                                                        <form className={classes.regForm + " " + classes.margin0}
                                                              onSubmit={handleSubmit} autoComplete="off">
                                                            <Typography variant="subtitle1" color="primary"
                                                                        className="f22 mb15">
                                                                {forms[activeForm].titleText}
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                {
                                                                    fields[activeForm].map((field: any, index) => {
                                                                        return (
                                                                                <React.Fragment key={index}>
                                                                                    <Grid item xs={12} sm={12} md={12}
                                                                                          className={""}>
                                                                                        <TextFieldCommon
                                                                                                {...field}
                                                                                                values={values}
                                                                                                onChange={(e: any) => {
                                                                                                    handleChange(e);
                                                                                                    if (field.name === "password") onPassWordChange(e);
                                                                                                }}
                                                                                                onBlur={handleBlur}
                                                                                                onClick={(e) => {
                                                                                                    if (field.name === "password" || field.name === "confirmPassword") {
                                                                                                        setShowPasswordRequirementSection(true);
                                                                                                    }
                                                                                                }}
                                                                                        />
                                                                                        <ErrorCommon errors={errors}
                                                                                                     name={field.name}
                                                                                                     touched={touched} />
                                                                                    </Grid>
                                                                                </React.Fragment>);
                                                                    })
                                                                }
                                                                {
                                                                        activeForm === 1 && <>
                                                                            <Grid item xs={12} sm={12} md={12}>
                                                                                <Typography variant="caption"
                                                                                            className="floatLeft w100 "
                                                                                            color="primary">Check spam for
                                                                                    otp.</Typography>
                                                                                <Typography variant="caption"
                                                                                            className="floatLeft w100 "
                                                                                            color="primary">Allow upto 5 min to
                                                                                    receive otp.</Typography>
                                                                                {!timerFinished &&
                                                                                        <Typography variant="caption"
                                                                                                    className="floatLeft w100 "
                                                                                                    color="primary">
                                                                                            Resend OTP in {formatTime(timeLeft)}
                                                                                        </Typography>
                                                                                }
                                                                                {timerFinished && (
                                                                                        <Link variant="caption"
                                                                                              className="floatLeft w100  textUnderLine"
                                                                                              color="primary"
                                                                                              onClick={() => handleResendClick(values)}>Click
                                                                                            here to resend new otp</Link>
                                                                                )}
                                                                            </Grid></>
                                                                }

                                                                <Grid item xs={12} sm={12} md={12}>
                                                                    {showPasswordRequirementSection && activeForm === 0 &&
                                                                            <PasswordRequired oneLetter={oneLetter}
                                                                                              oneNumber={oneNumber}
                                                                                              oneCapitalLetter={oneCapitalLetter}
                                                                                              eightCharacter={eightCharacter}
                                                                                              oneSpecialChar={oneSpecialChar}
                                                                            />
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12}>
                                                                    {
                                                                            activeForm == 0 &&
                                                                            <Link className="displayF floatLeft"
                                                                                  onClick={() => window.location.href = `${MARKETING_SITE_URL}chapters`}
                                                                                  variant="body2">
                                                                                <ArrowBackIcon /> Back </Link>
                                                                    }
                                                                    <Button
                                                                            type="submit"
                                                                            label={forms[activeForm].buttonText}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className="mb15 floatRight"
                                                                            disabled={(Object.keys(errors).length !== 0) || !dirty || isSubmitting}
                                                                            loading={isSubmitting}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12}
                                                                      className="textCenter">
                                                                    <Typography variant="body2"
                                                                                align="center"> Already have a
                                                                        account? <Link color="primary"
                                                                                       onClick={() => window.location.href = url}> Log
                                                                            in</Link>. If you need assistance
                                                                        please <Link color="primary"
                                                                                     onClick={() => setOpen(true)}> contact
                                                                            us</Link>. </Typography>
                                                                </Grid>

                                                                <LandingContactForm
                                                                        setOpen={(isOpen: boolean) => setOpen(isOpen)}
                                                                        open={open} {...props} />

                                                            </Grid>
                                                        </form>
                                                    </>
                                            );
                                        }}
                                    </Formik>
                                </Grid>
                                <Grid item xs={12} md={5} lg={5} className={classes.dpNone}>
                                    <Typography variant="h3" align="right"
                                                className={classes.textWhite + " " + classes.mr30 + " " + classes.titleMain}> Register </Typography>
                                    <img className={classes.floatRight + " " + classes.imgResponsive} src={logo2}
                                         alt="register" />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </React.Fragment>
    );
};

export default Register;
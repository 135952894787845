import React, { createContext } from "react";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../../dialog";
import { Button } from "../../../../formInputs";
import CheckWire from "./Content";
import { PolicyContextProvider } from "../../../../clientServicing/Payments/PolicyContext";
import RejectPayment from "../../../../RejectPayment";
import { QcPopUpContext } from "../QCPopUp/QCPopUp";
import { useActions } from "../../../../../actions";
import * as PaymentActions from "../../../../../actions/payment";
import * as EnrollmentActions from "../../../../../actions/enrollment";
import { processPayment } from "../../../../../actions/payment";
import { PaymentProcess } from "../../../../../constants";
import {
    epayRejectedDropdown, paymentRejectedDropdown,
} from "../../../../../pages/client/Enrollment/EnrollmentHome/basicInfo/PersonalDetailUtils";

export interface CheckWireProps {
    setOpenPopup: Function,
    openPopup: boolean,
    client?: any,
    refreshList?:Function
    enrollmentId?:string
}
export const CheckWireContext = createContext<{
    client?:any,
    enrollmentId?: string,
    setOpenPopup?: Function
    refreshList?:Function
}>({})

export default function CheckWirePopup(props: CheckWireProps) {
    const {openPopup, setOpenPopup, client, refreshList, enrollmentId} = props;
    const [showRp, setRp] = React.useState(false);
    const {updateCheckWireActionItem} = useActions(PaymentActions);
    const enrollmentActions = useActions(EnrollmentActions);
    const {processPayment} = useActions(PaymentActions);
    const handleClose = () => {
        setOpenPopup(false);
    };
    const updateEnrolmentStatus= async (enrollmentStatus, enrollmentSubStatus) =>{
        await enrollmentActions.updateEnrollment({ // need to update status and substatus, because of auto save functionality issue
            id: enrollmentId,
            enrollmentStatus,
            userId: client.userId,
            enrollmentSubStatus: enrollmentSubStatus,
            version: "v2",
        });
    }

    const dialogProps: GenericDialogProps = {
        actions: (
                <>
                    <Button
                            label={"Reject Payment"}
                            type="button"
                            variant="contained"
                            onClick={() => {
                                setRp(true)
                                // setOpenPopup(false);
                            }}
                            color="primary"
                            className="btnContainedRed"
                    />
                    <Button
                            form={"CheckWireForm"}
                            label={"Save"}
                            type="submit"
                            variant="contained"
                            color="primary"
                    />
                </>
        ),
        "aria-labelledby": "upload_proposal-pop-up-form-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: ( <PolicyContextProvider clientData={client}>
            <CheckWireContext.Provider value={{client, enrollmentId, setOpenPopup, refreshList}} >
                <CheckWire >
                    {showRp && <RejectPayment
                            onSubmit={async (values: any, actions: any)=>{
                                await updateEnrolmentStatus(3,0)
                                setRp(false);
                                await updateCheckWireActionItem({status:1, type:"reject", userId: client.userId, message: "", reason: values["paymentRejectedReason"]})
                                processPayment({type: PaymentProcess.checkWire, userId: client.userId, reason: rejectIngReason(values["paymentRejectedReason"]) })
                                setOpenPopup(false);
                                refreshList && refreshList()
                            }}
                            showRp={showRp}
                            setRp={setRp}
                    />}
                </CheckWire>
            </CheckWireContext.Provider>
        </PolicyContextProvider>),
        dividers: true,
        onClose: handleClose,
        open: openPopup,
        title: "Add Payment",
    };

    return (
            <>
                {
                        openPopup &&
                        <GenericDialog {...dialogProps} />
                }
            </>
    );
};

function rejectIngReason (reason: string ) {
    const selected = paymentRejectedDropdown.find((value, index, obj)=>{
        return  reason == value.value
    });
    return selected && selected.name
}




import { ActionType } from "../model";
import publicIp from "public-ip";


const paymentResponse = (response: { statusCode: number; message: any; body: any; }, dispatch: Function, message: string) => {
    if (response.statusCode === 400) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: response.message,
                statusCode: response.statusCode
            }
        });
        return false;
    } else {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: message,
                statusCode: 200
            }
        });
        return response.body;
    }
}

export const policyDetails = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const response = await api.post('payment/policy', payload, {});
        return response.body;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {success : false, message : err.message ? err.message : err};
    }
};

export const getPolicyDetails = (clientId:number) => async (dispatch: Function, getState: Function, api: { get: Function })  => {
    try {
        return await api.get('payment', {}, {clientId});
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const createPayment = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const ipAddress: string = await publicIp.v4();
        const resp = await api.post('payment', {ipAddress, ...payload}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Payment created successfully.",
                statusCode: 200
            }
        });
        return resp;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {statusCode: 400, success : false, message : err.message ? err.message : err};
    }
};

export const createPaymentSchedule = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const ipAddress: string = await publicIp.v4();
        const resp = await api.post('payment/scheduled', {ipAddress, ...payload}, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Payment done successfully.",
                statusCode: 200
            }
        });
        return resp.body;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {statusCode: 400, success : false, message : err.message ? err.message : err};
    }
};

export const createManualPayment = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const resp = await api.post('payment/mannual', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Payment created successfully.",
                statusCode: 200
            }
        });
        return resp.body;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {success : false, message : err.message ? err.message : err};
    }
};

export const processPayment = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const resp = await api.post('payment/process', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Payment Rejected successfully.",
                statusCode: 200
            }
        });
        return resp.body;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {success : false, message : err.message ? err.message : err};
    }
};

export const deleteManualPayment = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const resp = await api.delete('payment', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: "Payment deleted successfully.",
                statusCode: 200
            }
        });
        return resp.body;
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {success : false, message : err.message ? err.message : err};
    }
};

export const addPaymentMethod = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    const message: string = "Payment method added successfully.";
    try {
        const response = await api.post('payment/method', payload, {});
        return paymentResponse(response, dispatch, message);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {success : false, message : err.message ? err.message : err};
    }
};

export const removePaymentMethod = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    const successMessage: string = "Payment method removed successfully.";
    try {
        await api.delete('payment/method', payload, {});
        dispatch({
            type: ActionType.ALERT,
            payload: {
                message: successMessage,
                statusCode: 200
            }
        });
        return {success : true, message : successMessage};
    } catch(err) {
        dispatch({ 
            type: ActionType.ALERT,
            payload: {
                message: err.message ? err.message : err,
                statusCode: 400
            }
        });
        return {success : false, message : err.message ? err.message : err};
    }
};
export const createActionItem = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const response = await api.post('admin-actions/qc-action-item', payload, {});
        console.log("action item response: ", response);
        return response && response.body || {};
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};
export const createCheckWireActionItem = (payload: any) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const response = await api.post('admin-actions/check-wire', payload, {});
        console.log("action item response: ", response);
        return response && response.body || {};
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const updateQCActionItem = (payload: {status:string, userId:string, message:string, reason:string, type:string}) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const response = await api.put('admin-actions/qc-action-item', payload, {});
        console.log("action item response: ", response);
        return response && response.body || {};
    } catch(err) {
         dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return {success : false, message : err.message ? err.message : err};
    }
};

export const updateCheckWireActionItem = (payload: {status:string, userId:string, message:string, reason:string, type:string}) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        const response = await api.put('admin-actions/check-wire', payload, {});
        console.log("action item response: ", response);
        return response && response.body || {};
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getSaveSignedUrl = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function })  => {
    try {
        const response = await api.post('payment/document/save', payload, {});
        console.log("File Save: ", response);
        return response && response.body || {};
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};

export const getViewSignedUrl = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function })  => {
    try {
        const response = await api.post('payment/document/view', payload, {});
        console.log("File Get: ", response);
        return response && response.body || {};
    } catch(err) {
        return dispatch({
            type: ActionType.ALERT,
            payload: err
        });
    }
};


import { useFormikContext } from "formik";
import { Label } from "../../../../formInputs";
import { AutoComplete, ErrorCommon, NumberTextField, TextField, TextFieldCommon } from "../../../../formikFormInputs";
import selectField from "../../../../formikFormInputs/SelectField";
import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import UploadAndPreview from "../component/UploadAndPreview";
import { fieldsName } from "./utils";
import { usePolicyContext } from "../../../../clientServicing/Payments/PolicyContext";
import { RequestSupportingDocs } from "../../../../clientServicing/Payments/RequestSupportingDocs";
import { QcPopUpContext } from "./QCPopUp";
import { useActions } from "../../../../../actions";
import * as TrancheActions from "../../../../../actions/tranche";

let trancheDropDownArr: any[] = [{
    title: "",
    key: "",
    name: "",
    value: "",
}];
 const FormFields = () =>{
    const { values, handleChange, handleBlur, errors, touched } = useFormikContext<any>();
     const {policy, refetchPolicy}: any = usePolicyContext();
     const [trancheList, setTrancheList] = React.useState(trancheDropDownArr);
     const [trancheCompleteDetail, setTrancheCompleteDetail] = React.useState([]);
     const {client, enrollmentId}: any = useContext(QcPopUpContext);
     const trancheActions = useActions(TrancheActions)
    const bankAccountType = [
        { key: "PersonalChecking",value: 'PersonalChecking', name: 'Checking' },
        { key:"PersonalSavings",value: 'PersonalSavings', name: 'Savings' },
    ];
    console.log(policy, "==== policy")
    const disabled = true
     const getTranche = async () => {
         //fetch tranche whose deadline for Application is greater than current date
         const result = await trancheActions.getTrancheListForClient({ clientId: client && client.id });
         if (result && result.rows && result.rows.length > 0) {
             const resultArr: any[] = [];
             const arr = result.rows;
             setTrancheCompleteDetail(arr);
             arr.forEach(value => {
                 resultArr.push({
                     title: value.trancheNumber + "",
                     key: value.trancheNumber + "",
                     name: value.trancheNumber + "",
                     value: value.trancheNumber + "",
                 });
             });
             setTrancheList(resultArr);
         }
     };
     React.useEffect(() => {
         getTranche().then();
         return () => {
             setTrancheList(trancheDropDownArr);
             setTrancheCompleteDetail([]);
         };
     }, []);

    const bankDetailFields = [
        {
            label: <Label label={"Bank Name"} required={true}/>,
            id: fieldsName.bankName,
            name: fieldsName.bankName,
            placeholder: "Bank Name",
            component: TextField,
            disabled
        },
        {
            label: <Label label={"Bank Account Holder"} required={true}/>,
            id: fieldsName.bankAccHolder,
            name: fieldsName.bankAccHolder,
            placeholder: "Bank Account Holder",
            component: TextField,
            disabled
        },
        {
            label: <Label label={"Routing Number"} required={true}/>,
            id: fieldsName.routingNumber,
            name: fieldsName.routingNumber,
            placeholder: "Routing Number",
            component: NumberTextField,
            disabled
        },
        {
            label: <Label label={"Account Number"} required={true}/>,
            id: fieldsName.accountNumber,
            name: fieldsName.accountNumber,
            placeholder: "Account Number",
            component: TextField,
            disabled
        },
        {
            label: <Label label={"Account Type"} required={true}/>,
            id: fieldsName.accountType,
            name: fieldsName.accountType,
            placeholder: "Account Type",
            component: selectField,
            options: bankAccountType,
            disabled
        },
        {
            label: <Label label={"Notes"} required={true}/>,
            id: fieldsName.note,
            name: fieldsName.note,
            placeholder: "Notes",
            component: TextField,
            sm:12,
            md:12,
            disabled
        }
    ]
    const fieldObj  = [
        {
            label: <Label label={"Payment Method"} required={true}/>,
            id: fieldsName.paymentMethod,
            name: fieldsName.paymentMethod,
            placeholder: "Payment Method",
            component: TextField,
            disabled
        },
        {
            label: <Label label={"Tranche Number Assignment"} required={true}/>,
            id: fieldsName.trancheNo,
            name: fieldsName.trancheNo,
            placeholder: "Enter client list name",
            component: AutoComplete,
            contentList: trancheList,
        },
        {
            label: <Label label={"Amount due"} required={true}/>,
            id: fieldsName.amountDue,
            name: fieldsName.amountDue,
            placeholder: "Amount due",
            component: NumberTextField,
            disabled
        },
        {
            label: <Label label={"Amount paid"} required={true}/>,
            id: fieldsName.amountPaid,
            name: fieldsName.amountPaid,
            placeholder: "Enter client list name",
            component: NumberTextField,
            disabled
        },

    ]
    if(!policy){
       return <>No Policy</>
    }
    return<>
        <Grid container spacing={3}>
            {
                fieldObj.map((field, index) => {
                    return (
                            <React.Fragment key={index}>
                                <Grid item xs={12} sm={6} md={6} key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                    />
                                    <ErrorCommon errors={errors}
                                                 name={field.name}
                                                 touched={touched} />
                                </Grid>
                            </React.Fragment>);
                })
            }
            <Grid item xs={12}>
                <span className="hLine" />
                <Typography variant="body1" component="strong" className="floatLeft w100 mb0">Bank Account
                    Details</Typography>
            </Grid>
            {
                bankDetailFields.map((field:any, index) => {
                    return (
                            <React.Fragment key={index}>
                                <Grid item xs={field.xs || 12} sm={field.sm ||6} md={field.md || 6} key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                    />
                                    <ErrorCommon errors={errors}
                                                 name={field.name}
                                                 touched={touched} />
                                </Grid>
                            </React.Fragment>);
                })
            }
            <span className="hLine" />
            <Grid item xs={12} md={12} lg={6}>
                <UploadAndPreview
                        fileName={""}
                        fieldName={fieldsName.checkFront}
                        title="Upload check front"
                        disabled={true}
                        /*directory should be policy Number*/
                        directory={policy.policyNumber}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <UploadAndPreview
                        fileName={""}
                        fieldName={fieldsName.checkBack}
                        title={"Upload check back"}
                        disabled={true}
                        /*directory should be policy Number*/
                        directory={policy.policyNumber}
                />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
               <RequestSupportingDocs client={client} directory={enrollmentId} />
            </Grid>
        </Grid>
    </>

}

export default FormFields
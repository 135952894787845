import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog as GenericDialog, GenericDialogProps } from "./dialog";
import { Formik } from "formik";
import { Label } from "./formInputs";
import { SelectField, TextFieldCommon } from "./formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import { paymentRejectedDropdown } from "../pages/client/Enrollment/EnrollmentHome/basicInfo/PersonalDetailUtils";


export default function RejectPayment(props: any) {
    const initialValues = {};
    const validate = ((value: any) => {
        let errors: any = {};

        return errors;
    });
    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "terms-and-conditions-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <Typography variant="body1" className="mb15 floatLeft w100"> This ePay payment was rejected due
                        to</Typography>
                    <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validate={values => validate(values)}
                            validateOnChange={true}
                            onSubmit={async (e) => {
                                if(props.onSubmit){
                                    props.onSubmit(e)
                                }
                            }}
                    >
                        {(formikProps) => (

                                <RejectPaymentFormContent {...formikProps} />

                        )}
                    </Formik>
                </>
        ),
        dividers: true,
        onClose: () => props.setRp(false),
        open: props.showRp,
        title: "Reject Payment",
    };
    return (
            <GenericDialog {...dialogProps} />
    );
}

function RejectPaymentFormContent(props: any) {
    const fieldObj = [
        {
            name: "paymentRejectedReason",
            label: <Label label={"Payment was rejected"} required={true} />,
            isRequired: true,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: paymentRejectedDropdown,
        },
    ];
    return (
            <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                    {fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={1}
                                    />
                                </Grid>
                        );
                    })
                    }
                    <Grid item xs={12}>
                        <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="floatRight"
                                type="submit"

                        >
                            Reject Payment
                        </Button>
                    </Grid>
                </Grid>
            </form>
    );
}
